<template>
  
    <footer class="footer-main">
        <div class="container">
            <div class="row">

                <div class="col-4">
                    <div class="text-lg-start">
                        <h6 class="text-white mt-3">Comply with</h6>
		                
                        <div class="row mt-1">
                            <div class="col-8">
                                <a href="http://www.imo.org/en/OurWork/HumanElement/SafetyManagement/Pages/ISMCode.aspx" target="__blank"><img src="../../assets/images/ism.jpg" class="img-footer"></a>
                            </div>

                            <div class="col-4">
                                 <a href="http://www.imo.org/en/OurWork/Security/Guide_to_Maritime_Security/Pages/SOLAS-XI-2%20ISPS%20Code.aspx" target="__blank"><img src="../../assets/images/isps.png" class="img-footer"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-4">
                    <div class="text-center">
                        <h6 class="text-white mt-3">Follow Media Sosial Kami</h6>
                        <div class="block">
                            <ul class="social-links-footer list-inline">
                                <li class="list-inline-item">
                                    <a href="https://www.facebook.com/Pelayaran-Bahtera-Adhiguna-107644780762942" target="__blank"><i class="zmdi zmdi-facebook zmdi-hc-fw"></i></a>
                                </li>
                                <li class="list-inline-item">
        					        <a href="https://www.linkedin.com/company/pt-pelayaran-bahtera-adhiguna/" target="__blank"><i class="zmdi zmdi-linkedin zmdi-hc-fw"></i></a>
        					    </li>
        					    <li class="list-inline-item">
        					        <a href="https://www.instagram.com/bahteradhiguna/" target="__blank"><i class="zmdi zmdi-instagram zmdi-hc-fw"></i></a>
        					    </li>
        					    <li class="list-inline-item">
        					        <a href="https://www.youtube.com/channel/UCm-YdpMIgf9IshZeJnvp_EA" target="__blank"><i class="zmdi zmdi-youtube-play zmdi-hc-fw"></i></a>
        					    </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-4">
		            <div class="text-lg-end mt-3">
		                <div class="row">
		                    <div class="col-lg-9 col-sm-6">
		                        <p class="text-white text-member">Member of INSA</p>
		                        <p class="text-white text-member">No.22/INSA/VII/1990</p>
		                    </div>
		                    
		                    <div class="col-lg-3 col-sm-6">
		                        <a href="http://insa.or.id/" target="__blank"><img src="../../assets/images/insa.png" class="img-footer"></a>
		                    </div>
		                    
		                </div>
		            </div>
		        </div>

            </div>

            <div class="text-center mt-3">
		        <p class="text-white text-member">&copy; Copyright PT Pelayaran Bahtera Adhiguna 2022 /All Right Reserved</p>
		    </div>

        </div>
    </footer>

</template>

<script>
export default {
    name: 'myFooter'
}
</script>

<style scoped>
    .footer-main {
        background: #0158a7;
        padding: 0 0 20px;
        margin-top: -2px;
    }

    @media (max-width: 767px) {
        .footer-main {
            padding: 5px 0 10px;
        }
    }

    .img-footer {
        height: 60px;
        width: auto;
    }

    @media screen and (max-width: 767px) {
        .img-footer {
            height: 20px;
            width: auto;
        }
    }

    .footer-main .block ul.social-links-footer {
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        .footer-main .block ul.social-links-footer {
            margin-top: 15px;
            text-align: center;
        }
    }

    .footer-main .block ul.social-links-footer .list-inline-item:not(:last-child) {
        margin-right: 5px;
    }

    .footer-main .block ul.social-links-footer li a {
        display: block;
        height: 30px;
        width: 30px;
        text-align: center;
        border-radius: 20px;
        background: #fff;
    }

    @media screen and (max-width: 767px) {
        .footer-main .block ul.social-links-footer li a {
            height: 20px;
            width: 20px;
        }
    }

    .footer-main .block ul.social-links-footer li a i {
        line-height: 30px;
        color: #0158a7;
    }

    @media screen and (max-width: 720px) {
         .footer-main .block ul.social-links-footer li a i {
            font-size: 15px;
            margin-top: -14px;
         }
    }

    .footer-main .block ul.social-links-footer li a:hover {
        background: #ea2e23;
    }

    .footer-main .block ul.social-links-footer li a:hover i {
        color: #fff;
    }

    .text-member {
        font-size: 15px;
        font-weight: 300;
    }

    @media screen and (max-width: 720px) {
        .text-member {
            font-size: 7px;
            margin-bottom: -1px;
        }
    }
</style>